// Checkout.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
//import jsPDF from 'jspdf';
import useSessionId from "./useSessionId";
import html2pdf from 'html2pdf.js';

//import html2canvas from 'html2canvas';

const apiUrl = process.env.REACT_APP_API_URL;

const Paymentstatus = () => {

  const sessionId = useSessionId();


  const [orderItems, setOrderDetails] = useState([]);
  const [orderProduct, setOrderProduct] = useState([]);


  const [loading, setLoading] = useState(true);
  //const [tpStatus, setTpStatus] = useState(0);
  const [getOrderId, setGetOrderId] = useState('n/a');
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };
  const queryParams = getQueryParams(location.search);

  const statusId = queryParams.get('status_id');
  const order_id = queryParams.get('order_id');



  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 3000);
    //const sessionId = sessionStorage.getItem('sessionId');

    const fetchitems = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/fetch_tporder.php?sessionId=${sessionId}&order_id=${order_id}`
        );
        console.log(response);

        const data = await response.json();

        setOrderDetails(data.orderData);
        setOrderProduct(data.productData);

        //const tpstatus = data[0].tp_status;
        const get_OrderId = data.orderData[0].orderId;
        // setTpStatus(tpstatus);
        setGetOrderId(get_OrderId);

      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (sessionId) {
      fetchitems();
    }
  }, [sessionId, order_id]);

  function backTo() {
    navigate('/');
  }


  //console.log(`TP_Status: ${tpStatus}`);


  const handleSaveAsPDF = () => {
    const element = document.getElementById('toPrint');

    // Use the orderId from the first item in orderItems array for the filename
    const orderId = orderItems.length > 0 ? orderItems[0].orderId : 'toPrint';

    // Set the options for html2pdf including margins
    const options = {
      margin: [10, 15, 10, 15], // [top, left, bottom, right] in millimeters
      filename: `${orderId}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(element).set(options).save();
  };


  return (
    <div class=" bg-gray-100">

      <div class="flex flex-col items-center border-b bg-white py-4 sm:flex-row sm:px-10 lg:px-20 xl:px-32">
        <span class="text-2xl font-bold text-gray-800">Payment Gateway</span>
        <div class="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div class="relative">
            <ul class="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#"
                ><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
                  ></span>
                <span class="font-semibold text-gray-900">Shop</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-emerald-200 text-xs font-semibold text-emerald-700" href="#"
                ><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" /></svg
                  ></span>
                <span class="font-semibold text-gray-900">Shipping</span>
              </li>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
              </svg>
              <li class="flex items-center space-x-3 text-left sm:space-x-4">
                <span class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2" href="#">3</span>
                <span class="font-semibold text-gray-500">Payment</span>
              </li>
            </ul>
          </div>
        </div>
      </div>


      {loading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-100 z-50">
          <div className="w-72 flex flex-col items-center rounded-3xl border-4 bg-white px-6 py-8 text-center">
            <svg
              className="animate-spin h-16 w-16 text-red-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 12 12"
                  to="360 12 12"
                  dur="1s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
            <p className="mb-2 mt-2 text-lg font-medium text-gray-500">Loading...</p>
          </div>
        </div>
      )
        : <div className="">

          {orderItems.length > 0 ?


            <div class="mx-auto max-w-md ">
              <div class="bg-white shadow">
                <div class="px-4 py-6 sm:px-8 sm:py-10">


                  <div id="toPrint" class="w-full max-w-full overflow-hidden text-center text-sm lg:text-sm">

                    <div class="text-center">

                      <div class="flex items-start justify-between">

                        <div class="text-left">
                          <p class="text-sm font-base text-gray-500">
                            Vetrilabz Sdn.Bhd <br />
                            B-27-1, Jln Atmosphere 4 <br />
                            Pusat Perniagaan Atmosphere <br />
                            43300, Seri Kembangan, Selangor<br />
                            Tel: +6010-5495008 <br />
                            Email: admin@terraepawz.com
                          </p>
                        </div>


                        <div class="text-right">
                          <p class="text-2xl font-bold text-gray-700">Invoice</p>
                          <p class="text-xl font-semibold text-purple-800">{getOrderId}</p>
                        </div>

                      </div>

                      {/*<svg viewBox="0 0 24 24" class="text-green-400 w-16 h-16 mx-auto my-6">
<path fill="currentColor"
d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
</path>
</svg>*/}

                    </div>

                    {orderItems.map((item, index) => (
                      <div key={index}>

                        <div class="mt-6 border-t border-b py-2">

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Order Status</p>
                            <p class="font-semibold text-gray-900">

                              {statusId === '1' ?

                                <span class="py-1 px-2 mt-1 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                  <svg class="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                                    <path d="m9 12 2 2 4-4"></path>
                                  </svg>
                                  Successful
                                </span>


                                :

                                <span class="py-1 px-2 mt-1 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500">
                                  <svg class="shrink-0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="15" y1="9" x2="9" y2="15"></line>
                                    <line x1="9" y1="9" x2="15" y2="15"></line>
                                  </svg>
                                  Failed
                                </span>


                              }

                            </p>
                          </div>


                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Date/Time</p>
                            <p class="font-semibold text-gray-900">{item.trans_date}</p>
                          </div>



                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Order Id</p>
                            <p class="font-semibold text-gray-900">{item.orderId}</p>
                          </div>


                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Bill Code</p>
                            <p class="font-semibold text-gray-900">{item.billcode}</p>
                          </div>

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">ToyyibPay Ref</p>
                            <p class="font-semibold text-gray-900">{item.tp_ref}</p>
                          </div>

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Online Payment Status</p>
                            <p class="font-semibold text-gray-900">{item.tp_status}</p>
                          </div>


                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Mode</p>
                            <p class="font-semibold text-gray-900">{item.method}</p>
                          </div>

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Subtotal</p>
                            <p class="font-semibold text-gray-900">RM {item.subtotal}</p>
                          </div>

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Discount (if any)</p>
                            <p class="font-semibold text-gray-900">RM {item.discount}</p>
                          </div>

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Total</p>
                            <p class="font-semibold text-gray-900">RM {item.total}</p>
                          </div>

                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Postage</p>
                            <p class="font-semibold text-gray-900">RM {item.shipping}</p>
                          </div>


                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Grand Total</p>
                            <p class="font-semibold text-gray-900">RM {item.totalPayment}</p>
                          </div>




                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Product</p>

                            {orderProduct.map((item, index) => (
                              <div key={index}>
                                <p class="font-semibold text-gray-900">{item.title} x {item.qty}</p>
                              </div>
                            ))}

                          </div>




                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Name</p>
                            <p class="font-semibold text-gray-900">{item.name}</p>
                          </div>
                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Phone</p>
                            <p class="font-semibold text-gray-900">{item.phone}</p>
                          </div>
                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Address</p>
                            <p class="font-semibold text-gray-900 text-end">{item.address}, {item.city}, {item.zip}, {item.state}</p>
                          </div>
                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Referral</p>
                            <p class="font-semibold text-gray-900">{item.referral}</p>
                          </div>
                          <div class="flex items-center justify-between">
                            <p class="text-sm font-medium text-gray-500">Email</p>
                            <p class="font-semibold text-gray-900">{item.email}</p>
                          </div>

                        </div>

                        <div class="mt-2 flex items-center justify-center mb-4">
                          <p class="text-md font-medium text-gray-900 text-center">
                            Thank You for your purchase <br />
                            Please Come Again
                          </p>
                        </div>

                      </div>
                    ))}
                  </div>

                  <div class="mt-6 my-4 flex justify-center space-x-4">
                    <button onClick={backTo} type="button"
                      class="group inline-flex items-center justify-center rounded-md bg-purple-800 px-4 py-2 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
                      Back Home
                      <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:ml-8 ml-4 h-6 w-6 transition-all" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                      </svg>
                    </button>
                    <button onClick={handleSaveAsPDF} type="button"
                      class="group inline-flex items-center justify-center rounded-md bg-gray-500 px-4 py-2 text-lg font-semibold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800">
                      Save as Pdf
                    </button>
                  </div>

                </div></div></div>
            :

            <div class="mx-auto max-w-md ">
              <div class="bg-white shadow">
                <div class="px-4 py-6 sm:px-8 sm:py-10">



                  <div class="text-center mb-24"><svg viewBox="0 0 24 24"
                    className="text-gray-600 w-16 h-16 mx-auto my-6">
                    <path fill="currentColor"
                      d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1-15h-2v6h2V7zm0 8h-2v2h2v-2z">
                    </path>
                  </svg>
                    <h3 class="md:text-2xl text-base text-gray-600 font-semibold text-center">Error in fetching invoice details
                    </h3>
                  </div>

                </div></div></div>

          }


        </div>}


    </div>


  );
}

export default Paymentstatus;
