import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoCompany from '../images/terraepawz_logo.png';


//import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';


const apiUrl = process.env.REACT_APP_API_URL;

const NavbarX = () => {


  const [totalOrder, setTotalOrder] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const fetchData = () => {

    const sessionId = sessionStorage.getItem('sessionId');

    fetch(`${apiUrl}/api/data_trolley.php?sessionId=${sessionId}`)
      .then(response => response.json())
      .then(data => setTotalOrder(data.total_order))
      .catch(error => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 2000); // Fetch data every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);



  return (
    <header class="absolute top-0 w-full z-10 font-roboto">

      <nav class="container px-1 bg-transparent shadow navbar">

        <div class="flex items-center justify-between py-2">
          <div class="flex items-center space-x-4">
            <img src={LogoCompany} alt="Logo" class="w-12 h-12 mr-2" />
            <div>
              <span class="bg-gradient-to-r from-pink-500 to-indigo-500 bg-clip-text text-2xl font-bold text-stroke-white text-transparent sm:text-2xl">
                Terraepawz
              </span>
              <p class="text-sm text-gray-800">Pets Health Supplement</p>
            </div>
          </div>


          <div class="hidden sm:flex sm:items-center">

            <ul className="flex space-x-4">
              <li className="text-black">
                <Link to="/" class="flex items-center gap-2">
                  Home</Link>
              </li>
              <li className="text-black">
                <Link to="/Product" class="flex items-center gap-2">
                  Product</Link>
              </li>
              <div className="relative text-black">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="hover:text-gray-300 flex items-center"
                >
                  Services
                  <svg
                    className={`w-4 h-4 ml-1 transition-transform ${dropdownOpen ? 'transform rotate-180' : ''
                      }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {dropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-gray-900 bg-opacity-75 z-50 text-white py-2 rounded-md shadow-lg">
                    <a href="https://www.tiktok.com/@terraepawz?_t=8pMVaAd8Gt5&_r=1" className="block px-4 py-2 hover:bg-gray-800">TikTok</a>
                    <a href="http://shopee.com.my/terraepawz" className="block px-4 py-2 hover:bg-gray-800">Shopee</a>
                    <a href="https://www.facebook.com/share/hwwdndps9sPUKwmR/?mibextid=qi2Omg" className="block px-4 py-2 hover:bg-gray-800">Facebook</a>
                    <a href="https://www.instagram.com/terraepawz?igsh=aDYxdDF3Zm45bWtk" className="block px-4 py-2 hover:bg-gray-800">Instagram</a>
                    <a href="https://s.lazada.com.my/s.OtmHC" className="block px-4 py-2 hover:bg-gray-800">Lazada</a>
                  </div>
                )}
              </div>
            </ul>


          </div>

          <div class="hidden sm:flex sm:items-center gap-4 px-8">
            <div>
              <Link to="/Cart" class="text-gray-800 text-sm font-bold hover:text-purple-600 mb-1"><Badge badgeContent={totalOrder} color="primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>

              </Badge></Link>
            </div>
            {/*<a href="/Signin" class="text-gray-800 text-sm font-bold hover:text-purple-600 mr-4">Sign in</a>
            <a href="/SignUp" class="text-gray-800 text-sm font-bold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600">Sign up</a>*/}
          </div>

          <div class="sm:hidden cursor-pointer flex gap-4 ">
            <Link to="/Cart" class="text-gray-800 text-sm font-bold hover:text-purple-600 mb-1">
              <Badge badgeContent={totalOrder} color="primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>

              </Badge>
            </Link>
            <button
              className="md:hidden"
              onClick={() => setIsOpen(!isOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>


      {/* ============ Navbar Mobile View =============*/}
      {/* ============ Navbar Mobile View =============*/}


      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-75 z-50 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
          } transition-transform duration-300 ease-in-out`}
      >
        <div className="p-4">
          <button
            className="text-white"
            onClick={() => setIsOpen(false)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>
          <nav className="mt-4">
            <ul>
              <li className="text-white py-2"><Link to="/">Home</Link></li>
              <li className="text-white py-2"><Link to="/Product">Product</Link></li>

              <div className="relative text-white py-2">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="hover:text-gray-300 flex items-center"
                >
                  Services
                  <svg
                    className={`w-4 h-4 ml-1 transition-transform ${dropdownOpen ? 'transform rotate-180' : ''
                      }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {dropdownOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-gray-900 bg-opacity-75 z-50 text-white py-2 rounded-md shadow-lg">
                    <a href="https://www.tiktok.com/@terraepawz?_t=8pMVaAd8Gt5&_r=1" className="block px-4 py-2 hover:bg-gray-800">TikTok</a>
                    <a href="http://shopee.com.my/terraepawz" className="block px-4 py-2 hover:bg-gray-800">Shopee</a>
                    <a href="https://www.facebook.com/share/hwwdndps9sPUKwmR/?mibextid=qi2Omg" className="block px-4 py-2 hover:bg-gray-800">Facebook</a>
                    <a href="https://www.instagram.com/terraepawz?igsh=aDYxdDF3Zm45bWtk" className="block px-4 py-2 hover:bg-gray-800">Instagram</a>
                    <a href="https://s.lazada.com.my/s.OtmHC" className="block px-4 py-2 hover:bg-gray-800">Lazada</a>
                  </div>
                )}
              </div>
              {/*<div className="text-end">
                <a href="SignIn" class="text-white text-sm font-bold hover:text-purple-600 mr-4">Sign in</a>
                <a href="SignUp" class="text-white text-sm font-bold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600">Sign up</a>
              </div>*/}
            </ul>
          </nav>
        </div>
      </div>
    </header>

  );
};

export default NavbarX;