import React from 'react';
import Order from '../components/Order';

const OrderM = () => {
  return (

<Order/>

  );
};

export default OrderM;